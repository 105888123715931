/**
 * The initialization of jquery is being pulled off into its own file because of ordering issues.
 *
 * The "jquery-migrate" module needs the jQuery variable to be in the global scope, so 'jQuery' needs to be stuck on 'window'. But if I
 * put all the imports in one file, IntelliJ will try to reorganize all the imports before the code, so the 'window' assignments will be
 * moved after the imports, and loading will fail.  To fix this, I'm pulling jQuery out into its own file.
 */
import * as $ from "jquery";

(<any>window).$ = $;
(<any>window).jQuery = $;

export {};