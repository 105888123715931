import "./initializeJQuery"
import "popper.js";
import "bootstrap";
import * as jsonInterfaceGenerator from "../../../target/generated-typescript/jsonInterfaceGenerator";
import "bootstrap/dist/css/bootstrap.css";
import "./font-awesome.css";
import "./lato.css";
import "./landing-page.css";
import {InfoRequest} from "../../../target/generated-typescript/com_bluecirclesoft_cultivate_landing";
import {sendRequest} from "../../../target/generated-typescript/com_bluecirclesoft_cultivate_landing_SendRequest";

namespace main {

    jsonInterfaceGenerator.setCallAjax((url: string,
                                        method: string,
                                        data: any,
                                        isBodyParam: boolean,
                                        options: jsonInterfaceGenerator.JsonOptions<any>) => {
        let error = false;
        let settings: JQueryAjaxSettings = {
            method: method,
            data:   data,
            async:  options.hasOwnProperty("async") ? options.async : true
        };
        if (options.success) {
            let fn = options.success;
            settings["success"] = (responseData: any, textStatus: string, jqXHR: JQueryXHR) => {
                fn(responseData);
            };
        }
        if (options.error) {
            let fn = options.error;
            settings["error"] = (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                error = true;
                fn(errorThrown);
            };
        }
        if (options.complete) {
            let fn = options.complete;
            settings["complete"] = (jqXHR: JQueryXHR, textStatus: string) => {
                fn(error);
            };
        }
        if (isBodyParam) {
            settings["contentType"] = "application/json; charset=utf-8";
        } else {
            settings["dataType"] = "json";
        }
        const submitUrl = jsonInterfaceGenerator.getPrefix() + url;
        console.log("submitUrl == " + submitUrl)
        $.ajax(submitUrl, settings);
    });

    /**
     * Read the fields off the form, and submit the email.
     */
    function submitEmail() {
        let request: InfoRequest;
        let options: jsonInterfaceGenerator.JsonOptions<string>;
        request = {
            name:  $("#form-name").val() + "",
            email: $("#form-email").val() + "",
            text:  $("#form-about-yourself").val() + ""
        };
        options = {
            async:   true,
            success: (data) => {
                (<any>$("#contactModal")).modal('hide');
                if (data) {
                    (<any>$("#submitSuccessModal")).modal('show');
                } else {
                    (<any>$("#submitFailureModal")).modal('show');
                }
            },
            error:   () => {
                (<any>$("#contactModal")).modal('hide');
                (<any>$("#submitFailureModal")).modal('show');
            },
        };
        sendRequest(request, options);
    }

    /**
     * Resize the images
     */
    function resizeSvgImages() {
        // fraction of available space to use for icon
        const iconWidthFraction = .4;

        $("img.img-fluid").each(function () {
            // expand svg images to desired width and center them
            const $this = $(this);
            const $parent = $this.parent();

            const w = $parent.width();
            if (w) {
                $this.width(w * iconWidthFraction);
                const newMargin = (w * (1 - iconWidthFraction)) / 2;

                if (newMargin > 0) {
                    $this.css("marginLeft", newMargin);
                    $this.css("marginRight", newMargin);
                }
            }
        });
    }

    /**
     * Perform initial setup
     * @param {string} contextPath the context path of the WAR (for our AJAX calls)
     */
    export function setup(contextPath: string) {
        // set our context path for AJAX calls
        jsonInterfaceGenerator.init(contextPath);

        // set the event handler on the 'contact us' button
        $("#contact-form").on('submit', (e) => {
            try {
                e.preventDefault();
                submitEmail();
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        });

        // resize the SVG images on screen resize
        let resizeTimer: NodeJS.Timeout | undefined;
        $(window).on('resize', () => {
            if (resizeTimer) {
                clearTimeout(resizeTimer);
            }
            resizeTimer = setTimeout(resizeSvgImages, 100);
        });

        // resize the SVG images on initial load
        resizeSvgImages();
    }
}

(<any>window).main = main;

$(() => {
    const restRoot = $('head').attr("data-rest-root");
    if (typeof restRoot === "string") {
        main.setup(restRoot);
    } else {
        console.error("Could not find context path on head element")
    }
});
